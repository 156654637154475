<template>
  <home-template>
    <div class="text-start" slot="content">
      <div class="merchat-top-container">
        <v-row class="merchat-top-container-title-label">
          <div class="text-title">{{ name }}</div>
        </v-row>
        <v-row class="tabs tabs-with-paddings">
          <v-tabs v-model="tab">
            <v-tab class="pl-0"> Info </v-tab>
            <v-tab> Map </v-tab>
            <v-tab v-show="showInstructionsTab"> Instructions </v-tab>
            <v-tab v-if="auth.isParkmanAdmin()"> Advanced settings </v-tab>
          </v-tabs>
        </v-row>
        <v-row> </v-row>
      </div>
      <v-row>
        <v-tabs-items v-model="tab">
          <v-tab-item class="tab-item with-horizontal-padding">
            <v-divider />
            <tab-info
              ref="tabInfo"
              :location="currentLocation"
              @validated="tapInfoValidated"
              @formData="tapInfoFormData"
              @changed="tapInfoChanged"
              @locationDeleted="locationDeleted"
              :disabled="!isParkmanAdmin"
            />
          </v-tab-item>

          <v-tab-item class="tab-item with-horizontal-padding--map">
            <v-divider />
            <tab-map
              ref="tabMap"
              :zones="[currentLocation]"
              :zone="currentLocation"
              :zoneId="currentLocation ? currentLocation.id : -1"
              @validated="tapMapValidated"
              @formData="tapMapFormData"
              @changed="tapMapChanged"
              :disabled="!isParkmanAdmin"
            />
          </v-tab-item>
          <v-tab-item class="tab-item with-horizontal-padding">
            <v-divider />
            <tab-instruction
              @edit="editInstruction"
              :data="formData"
              :locationId="parseInt(currentLocationId)"
              @openAddForm="openAddForm"
            />
          </v-tab-item>
          <v-tab-item class="tab-item with-horizontal-padding">
            <v-divider />
            <tab-advanced
              ref="tabSettings"
              :location="currentLocation"
              @changed="tapSettingsChanged"
              @validated="tapSettingsValidated"
              @formData="tapAdvancedFormData"
            />
          </v-tab-item>
        </v-tabs-items>
      </v-row>
      <v-card
        v-if="hasChanged && showSaveForm && auth.isParkmanAdmin()"
        :elevation="6"
        class="footer"
      >
        <v-card-actions class="justify-end">
          <v-btn
            class="pkmn-button"
            color="primary"
            outlined
            large
            @click="cancelChanges"
          >
            Cancel changes
          </v-btn>
          <v-btn
            class="pkmn-button next-button pkmn-button--has-border"
            color="primary"
            large
            :disabled="!allFormsValid"
            @click="saveLocation"
          >
            {{ $t('form_save') }}
          </v-btn>
        </v-card-actions>
      </v-card>
      <v-card
        v-if="pending && auth.isParkmanAdmin()"
        :elevation="6"
        class="footer"
      >
        <v-card-actions class="justify-end">
          <v-btn
            class="pkmn-button"
            color="warning"
            large
            @click="saveLocation"
            :disabled="!allFormsValid"
          >
            {{ $t('confirm') }}
          </v-btn>
        </v-card-actions>
      </v-card>

      <v-card
        v-if="waitingForDeletion && auth.isParkmanAdmin()"
        :elevation="6"
        class="footer"
      >
        <v-card-actions class="justify-end">
          <v-btn
            class="pkmn-button"
            color="warning"
            outlined
            large
            @click="rejectDeletion"
            :disabled="!allFormsValid"
          >
            {{ $t('reject') }}
          </v-btn>
          <v-btn
            class="pkmn-button"
            color="warning"
            large
            @click="deleteLocation"
          >
            {{ $t('confirm_location_deletion') }}
          </v-btn>
        </v-card-actions>
      </v-card>
      <add-new-instruction-form
        v-if="isShowingAddForm"
        :editMode="addFormEditMode"
        :instructionTypeId="instructionTypeId"
        :locationId="parseInt(currentLocationId)"
        @close="isShowingAddForm = false"
      />
      <form-confirm-unsave
        v-if="confirmUnsaveDialogVisible"
        flow="location editing"
        @confirm="goToNext"
        @close="confirmUnsaveDialogVisible = false"
      />
    </div>
  </home-template>
</template>

<script>
import auth from '@/auth'
import HomeTemplate from '@/templates/Locations'
import TabInfo from '@/components/Locations/AddLocation/InfoForm.vue'
import TabAdvanced from '@/components/Locations/AddLocation/AdvancedSettingsForm.vue'
import TabMap from '@/components/Locations/AddLocation/MapForm.vue'
import TabInstruction from '../components/Locations/Location/InstructionForm.vue'
import AddNewInstructionForm from '../components/Locations/Location/FormAddNewInstruction.vue'
import merchantHelpers from '@/helpers/merchant'
import * as config from '@/config/config'
import i18n from '@/plugins/i18n'
import FormConfirmUnsave from '@/components/Merchant/Form/FormConfirmUnsave.vue'
import beforePageLeave from '@/components/Merchant/mixins/beforePageLeave'
import rules from '@/helpers/validation'

const PRICING_ACTIVATED_STATUS = 3

export default {
  name: 'MerchantLocationView',
  components: {
    HomeTemplate,
    TabInfo,
    TabAdvanced,
    TabMap,
    TabInstruction,
    AddNewInstructionForm,
    FormConfirmUnsave
  },
  mixins: [beforePageLeave],
  data() {
    return {
      i18n: i18n,
      auth: auth,
      rules,
      isEditingUserInfo: false,
      isFirstLoad: true,
      isShowingDeleteAccountPopup: false,
      isDeletingUser: false,
      activeForm: 'info',
      id: '',
      selectedButton: 'info',
      isShowingAddForm: false,
      addFormEditMode: false,
      instructionTypeId: 0,
      currentLocationSnapshot: '',
      formData: [
        {
          type: 'Before confirming a location',
          title: 'Welcome to our app',
          message: 'Thank you for choosing our app. We hope you enjoy it!',
          phone: '+358 00 000 00 00',
          force: true,
          id: 1
        },
        {
          type: 'Before confirming a location',
          title: 'Get started with our app',
          message: 'Create your account now to start using our app!',
          phone: '',
          force: true,
          id: 2
        },
        {
          type: 'Before parking started',
          title: 'Thank you for your purchase',
          message:
            'Your purchase has been successful. Thank you for choosing our app!',
          phone: '',
          force: false,
          id: 3
        },
        {
          type: 'During the visit',
          title: 'New features available',
          message: 'We have added new features to our app. Check them out now!',
          phone: '',
          force: false,
          id: 4
        },
        {
          type: 'During the visit',
          title: 'Help us improve',
          message:
            'We value your feedback. Please let us know how we can improve our app!',
          phone: '+358 00 000 00 00',
          force: false,
          id: 5
        },
        {
          type: 'During the visit',
          title: 'Limited time offer',
          message:
            'Get 50% off on your next purchase. Use code SAVE50 at checkout!',
          phone: '',
          force: true,
          id: 6
        }
      ],
      tab: '',
      instructionData: null,
      isTapInfoValidate: true,
      isTapMapValidate: true,
      isTapAdvancedValidate: true,
      infoForm: {},
      mapForm: {},
      advancedForm: {},
      tabInfoHasChange: false,
      tabMapHasChange: false,
      tabSettingsHasChange: false
    }
  },
  methods: {
    tapInfoValidated(valid) {
      this.isTapInfoValidate = valid
    },
    openAddForm(item) {
      this.instructionTypeId = item.id
      this.addFormEditMode = false
      this.isShowingAddForm = true
    },
    tapMapValidated(valid) {
      this.isTapMapValidate = valid
    },
    tapSettingsValidated(valid) {
      this.isTapAdvancedValidate = valid
    },
    tapInfoChanged(changed) {
      this.tabInfoHasChange = changed
    },
    tapSettingsChanged(changed) {
      this.tabSettingsHasChange = changed
    },
    tapMapChanged(changed) {
      this.tabMapHasChange = changed
    },
    tapInfoFormData(data) {
      this.infoForm = data
    },
    tapMapFormData(data) {
      this.mapForm = data
    },
    tapAdvancedFormData(data) {
      this.advancedForm = data
    },
    editInstruction(item) {
      this.addFormEditMode = true
      this.isShowingAddForm = true
      this.instructionTypeId = item.id
    },
    async fetchMerchant() {
      await this.$store.dispatch('setShowOverlay', true)
      const merchant = await merchantHelpers.fetchMerchant(
        this.$route.params.merchantId
      )
      if (!merchant) {
        await this.$router.push({ name: 'AllMerchants' })
        await this.$store.dispatch('setSidebar', config.SIDEBAR.MERCHANT_ALL)
        return
      }
      await this.$store.dispatch('setCurrentMerchant', merchant)
    },
    async getLocations() {
      const locationId = parseInt(this.$route.params.locationId)
      const locations = await merchantHelpers.getLocationsByMerchant(
        this.$route.params.merchantId
      )
      await this.$store.dispatch('setSidebar', config.SIDEBAR.MERCHANT)
      await this.$store.dispatch('setMerchantLocations', locations)
      for (let i = 0; i < locations?.length; i++) {
        if (locations[i].id === locationId) {
          const location = locations[i]
          this.currentLocationSnapshot = JSON.stringify(
            location,
            (key, value) => (key === 'hiddenMarker' ? null : value)
          )
          await this.$store.dispatch('setCurrentLocation', location)
          break
        }
      }
    },
    async locationDeleted() {
      if (auth.isParkmanAdmin()) {
        await this.$router.push({
          name: 'Merchant',
          params: { merchantId: this.$route.params.merchantId }
        })
        await this.$store.dispatch('setSystemMessage', {
          message: `${this.location.name} was deleted`,
          type: 'error'
        })
      } else {
        await this.getLocations()
        await this.$store.dispatch('setSystemMessage', {
          message: `${i18n.t('change_sent_review')}`,
          type: 'success'
        })
      }
    },
    async saveLocation() {
      if (!this.$refs.tabInfo?.rulesValidation() && this.isInfoTab) {
        return
      }
      if (!this.$refs.tabMap?.rulesValidation() && this.isMapTab) {
        return
      }
      if (!this.$refs.tabSettings?.rulesValidation() && this.isSettingsTab) {
        return
      }

      if (!this.isTapInfoValidate) {
        await this.$store.dispatch('setSystemMessage', {
          message: `${i18n.t('info_tab_has_errors')}`,
          type: 'error',
          once: true
        })
      }
      if (!this.isTapMapValidate) {
        await this.$store.dispatch('setSystemMessage', {
          message: `${i18n.t('map_tab_has_errors')}`,
          type: 'error',
          once: true
        })
      }
      if (!this.isTapAdvancedValidate) {
        await this.$store.dispatch('setSystemMessage', {
          message: `${i18n.t('advanced_settings_tab_has_errors')}`,
          type: 'error',
          once: true
        })
      }

      if (this.allFormsValid) {
        const addLocationFormData = {
          ...this.infoForm,
          ...this.mapForm,
          ...this.advancedForm
        }
        addLocationFormData.currency_id = parseInt(
          addLocationFormData.currency_id
        )
        addLocationFormData.z_index = parseInt(addLocationFormData.z_index)
        addLocationFormData.capacity = addLocationFormData.capacity
          ? parseFloat(addLocationFormData.capacity)
          : null
        addLocationFormData.commission_fixed = parseFloat(
          addLocationFormData.commission_fixed
        )
        addLocationFormData.commission_percentage = parseFloat(
          addLocationFormData.commission_percentage
        )
        addLocationFormData.kick_back_fee_fixed = parseFloat(
          addLocationFormData.kick_back_fee_fixed
        )
        addLocationFormData.kick_back_fee_percentage = parseFloat(
          addLocationFormData.kick_back_fee_percentage
        )
        addLocationFormData.vat_percentage = parseFloat(
          addLocationFormData.vat_percentage
        )

        addLocationFormData.merchant_id = this.currentMerchantId
        const location = await merchantHelpers.updateLocation(
          this.currentLocationId,
          addLocationFormData
        )
        if (location) {
          const locations = await merchantHelpers.getLocationsByMerchant(
            this.currentMerchantId
          )
          this.currentLocationSnapshot = JSON.stringify(
            location,
            (key, value) => (key === 'hiddenMarker' ? null : value)
          )
          await this.$store.dispatch('setMerchantLocations', locations)
          await this.$store.dispatch('setCurrentLocation', location)
          const currentMerchant = this.$route.params.merchantId
          await this.$router.push({
            name: 'Location',
            params: { merchantId: currentMerchant, locationId: location.id }
          })
        }
      }
    },
    async rejectDeletion() {
      const location = await merchantHelpers.rejectDeleteLocation(
        this.currentLocationId
      )
      const locations = await merchantHelpers.getLocationsByMerchant(
        this.currentMerchantId
      )
      await this.$store.dispatch('setMerchantLocations', locations)
      await this.$store.dispatch('setCurrentLocation', location)
      const currentMerchant = this.$route.params.merchantId
      await this.$router.push({
        name: 'Location',
        params: { merchantId: currentMerchant, locationId: location.id }
      })
    },
    updateFormDataFromLocation(location) {
      Object.assign(this.mapForm, {
        street_address: location.street_address
          ? location.street_address
          : null,
        postal_code: location.postal_code,
        state: location.state,
        entry_points: location.entry_points,
        polygon: location.polygon,
        centre_point: location.centre_point,
        city_id: location.city_id,
        country_id: location.country_id
      })
      if (this.isParkmanAdmin) {
        Object.assign(this.advancedForm, {
          z_index: location.z_index.toString(),
          currency_id: location.currency_id.toString(),
          vat_percentage: location.vat_percentage
            ? location.vat_percentage.toString()
            : '0',
          kick_back_fee_fixed: location.kick_back_fee_fixed
            ? location.kick_back_fee_fixed.toString()
            : '0',
          kick_back_fee_percentage: location.kick_back_fee_percentage
            ? location.kick_back_fee_percentage.toString()
            : '0',
          commission_fixed: location.commission_fixed
            ? location.commission_fixed.toString()
            : '0',
          commission_percentage: location.commission_percentage
            ? location.commission_percentage.toString()
            : '0'
        })
      }
      this.currentLocationSnapshot = JSON.stringify(location, (key, value) =>
        key === 'hiddenMarker' ? null : value
      )
    },
    async deleteLocation() {
      await this.$store.dispatch('setShowOverlay', true)
      if (auth.isParkmanAdmin()) {
        await merchantHelpers.deleteLocation(
          this.$route.params.locationId,
          'delete'
        )
        await this.locationDeleted()
      }
      await this.$store.dispatch('setShowOverlay', false)
    },
    async cancelChanges() {
      const location = this.currentLocation
      if (this.$refs.tabInfo) {
        this.$refs.tabInfo.updateFormDataFromLocation(location)
      }
      if (this.$refs.tabMap) {
        this.$refs.tabMap.resetForm(location)
      }
      if (this.$refs.tabSettings) {
        this.$refs.tabSettings.updateFormDataFromLocation(location)
      }
    },
    scrollToElement() {
      const locationId = this.$route.params.locationId
      if (!locationId) {
        return
      }

      const el = this.$el.querySelector(
        `.location-list-item[data-id='${locationId}']`
      )

      if (el) {
        el.scrollIntoView()
      }
    }
  },
  computed: {
    isUpdating() {
      return this.$store.state.isUpdatingLocation
    },
    allFormsValid() {
      return (
        this.isTapInfoValidate &&
        this.isTapMapValidate &&
        this.isTapAdvancedValidate
      )
    },
    isInfoTab() {
      return this.tab === 0
    },
    isMapTab() {
      return this.tab === 1
    },
    isSettingsTab() {
      return this.tab === 2
    },
    currentLocation() {
      return this.$store.getters.currentLocation
    },
    hasChanged() {
      if (this.auth.isParkmanAdmin()) {
        return (
          this.tabInfoHasChange ||
          this.tabSettingsHasChange ||
          this.tabMapHasChange
        )
      } else {
        return this.tabInfoHasChange || this.tabMapHasChange
      }
    },
    showSaveForm() {
      return !this.isSettingsTab
    },
    currentMerchantId() {
      return this.$route.params.merchantId
    },
    currentLocationId() {
      return this.$route.params.locationId
    },
    showInstructionsTab() {
      return this.$store.getters.currentProduct !== null
    },
    pending() {
      if (this.currentLocation) {
        return this.currentLocation.status_name === 'pending'
      }
      return false
    },
    waitingForDeletion() {
      if (this.currentLocation) {
        return this.currentLocation.status_name === 'waiting_for_deletion'
      }
      return false
    },
    isParkmanAdmin() {
      return this.auth.isParkmanAdmin()
    },
    name() {
      return this.$store.getters.currentLocation?.name
    }
  },
  async mounted() {
    await this.$store.dispatch('setShowOverlay', true)
    if (
      this.$store.getters.currentLocation === null ||
      this.currentLocationId !== this.$store.getters.currentLocation.id
    ) {
      await this.fetchMerchant()
      await this.getLocations()
    }
    const schedulePrices = await merchantHelpers.getPricingSchedule(
      this.$route.params.merchantId,
      parseInt(this.$route.params.locationId)
    )
    if (schedulePrices?.length) {
      this.currentLocation.pricing_activated = schedulePrices.some(
        (item) => item.status_id === PRICING_ACTIVATED_STATUS
      )
    }
    this.updateFormDataFromLocation(this.currentLocation)
    await this.$store.dispatch('setShowOverlay', false)

    this.scrollToElement()
  },
  watch: {
    currentLocation(newValue) {
      if (newValue) {
        this.updateFormDataFromLocation(newValue)
      }
    },
    hasChanged(newValue) {
      this.$store.commit('SET_LOCATION_UPDATING_STATUS', newValue)
    }
  },
  destroyed() {
    this.$store.dispatch('setCurrentLocation', null)
  },
  beforeRouteUpdate(to, from, next) {
    if (to.params.locationId !== from.params.locationId) {
      next();
      this.getLocations();
      this.$store.dispatch('setShowOverlay', false);
    }
  }
}
</script>

<style lang="scss" scoped>
@import '~@/style/common';
@import '~@/style/variables';
.add-user {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 8px 12px;
  gap: 10px;
}
.button-panel {
  padding: 0px 68px 40px;
}
.footer {
  position: absolute;
  padding: 8px 32px;
  bottom: 0;
  right: 0;
  left: $sidebar-width;
}
</style>
